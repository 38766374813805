import React from 'react'
//components
import { EditorRow } from 'components/seatingChart/editor/EditorRow'
import { EditorSectionOutline } from 'components/seatingChart/editor/EditorSectionOutline'
import { SeatingChartObject } from 'components/seatingChart/shared/objects/SeatingChartObject'
import {
	SEAT_SIZE,
	SEAT_SPACING,
	SEAT_SPACING_Y,
} from 'components/seatingChart/shared/SeatingChartConfig'
//util
import { LinearFunction, VectorUtils, GraphicsUtils } from 'util/MathFunctions'
import { OtColors } from 'util/OtColors'
//styles
import 'sass/components/seatingChart/SeatingChart.scss'

import { EditorConstants } from 'components/seatingChart/editor/EditorConstants'
const { PROPERTY_TOOLS } = EditorConstants

export class EditorSection extends SeatingChartObject {
	static getAbsoluteBoundingRect(data, positions) {
		const rect = EditorSection.getBoundingRect(data, positions)
		return GraphicsUtils.rotateRectangle(rect, data.rotation, rect.center)
	}

	static getBoundingRect(data, positions) {
		const [x, y] = EditorSection.getSectionPosition(data, positions)
		const width = EditorSection.calculateWidth(data)
		const height = EditorSection.calculateHeight(data)

		return {
			x,
			y,
			width,
			height,
			center: [x + width / 2, y + height / 2],
		}
	}

	static getMaxLabelWidth(data) {
		const maxChars = data.rows
			.map((row) => row.name)
			.reduce((acc, name) => Math.max(name.length, acc), 0)

		return (maxChars * SEAT_SIZE) / 2
	}

	static getMaxRowSize(data) {
		if (data.maxPosition === null) {
			return null
		}
		return 1 + (data.maxPosition - data.minPosition)
	}

	static getRowYFunction(data) {
		return new LinearFunction(SEAT_SIZE + data.rowSpacing, 0)
	}

	static getSectionPosition(data, sectionPositions) {
		if (sectionPositions) {
			const sectionPosition = sectionPositions[data.id]
			if (sectionPosition) {
				return [
					sectionPosition.posX * (SEAT_SIZE + SEAT_SPACING),
					sectionPosition.posY * (SEAT_SIZE + SEAT_SPACING_Y),
				]
			}
		} else {
			return [
				data.posX * (SEAT_SIZE + SEAT_SPACING),
				data.posY * (SEAT_SIZE + SEAT_SPACING_Y),
			]
		}
	}

	static calculateHeight(data) {
		const size = new LinearFunction(EditorSection.getMaxRowSize(data), 0)
		return size.evaluateAt(data.rows.length + 1)
	}

	static calculateWidth(data) {
		const seatPositions = EditorSection.getMaxRowSize(data) + 1 // +1 for row label
		return (
			seatPositions * SEAT_SIZE +
			(seatPositions + 1) * SEAT_SPACING +
			EditorSection.getMaxLabelWidth(data)
		)
	}

	static findSeatPosition(section, seatId) {
		const { skewX, rows } = section
		const skew = new LinearFunction(
			skewX || 0,
			skewX < 0 ? -skewX * (rows.length - 1) : 0
		).generate()
		const rowY = EditorSection.getRowYFunction(section).generate()
		const sectionSize = EditorSection.getMaxRowSize(section)
		const width = EditorSection.calculateWidth(section)
		for (let i = 0; i < rows.length; i++) {
			const seatPosition = EditorRow.findSeatPosition(
				sectionSize,
				width,
				section,
				section.rows[i],
				seatId
			)
			const rowPosition = [skew.next().value[1], rowY.next().value[1]]
			if (seatPosition) {
				return VectorUtils.add(rowPosition, seatPosition)
			}
		}
	}

	state = {
		sectionPathBounds: null,
		path:
			this.props.data && this.props.data.outline ? this.props.data.ouline : '',
	}

	get type() {
		return 'SECTION'
	}

	seatOpacity() {
		return 1
	}

	shouldDisableSeatSelection() {
		return this.seatOpacity() < 1
	}

	renderRows() {
		if (!this.props.data) {
			return null
		}
		const { data, positions } = this.props
		const rowData = data.rows
		const skew = new LinearFunction(
			data.skewX || 0,
			data.skewX < 0 ? -data.skewX * (rowData.length - 1) : 0
		).generate()
		const rowY = EditorSection.getRowYFunction(data)
		const { width, height } = EditorSection.getBoundingRect(data, positions)
		const maxRowSize = EditorSection.getMaxRowSize(data)

		return rowData.map((row, i) => {
			return (
				<svg
					className='row_svg'
					key={i}
					x={skew.next().value[1]}
					y={rowY.evaluateAt(row.rowNumber)}
				>
					<svg x={0} width={width} height={Math.abs(height)}>
						<EditorRow
							disableSeatSelect={this.shouldDisableSeatSelection()}
							data={row}
							sectionId={data.id}
							sectionOffset={data.minPosition}
							showSeatNumber={true}
							textRotation={data.rotation}
							sectionSize={maxRowSize}
							seatAlign={data.seatAlign}
							curve={data.curve}
							width={width}
							skewY={data.skewY}
						/>
					</svg>
				</svg>
			)
		})
	}

	render() {
		const { data, isSelected, selectedTool, hasSectionLayout, showSeats } =
			this.props

		const { path } = this.state
		const d = path || EditorSectionOutline.getSectionOutline(data)

		return (
			<g className='ot_editorSection'>
				<g className='animate'>
					<path
						id={data.id}
						className='chart_object'
						d={d}
						fill={OtColors.white}
						stroke={isSelected ? '#0099F3' : '#888'}
						strokeWidth='4'
					/>
				</g>
				{hasSectionLayout && !showSeats && (
					<g
						className='animate'
						style={{
							opacity:
								selectedTool.property === PROPERTY_TOOLS.OUTLINE ? 0.5 : 1,
						}}
					>
						{this.renderRows()}
					</g>
				)}
			</g>
		)
	}
}
