import styled from 'styled-components'
import { colors, theme } from '../../../../../theme'

export const TicketEl = styled.div`
	width: 100%;

	cursor: pointer;
	background-color: ${({ selected }) =>
		selected ? colors.primary.faded : colors.lightGrey};
	transition: background-color 0.3s ease; // Add this line for transition effect

	&:not(:last-child) {
		border-bottom: 1px solid ${colors.grey};
	}
`

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 18px ${theme.padding.md} 18px ${theme.padding.md};
`

export const TicketName = styled.p`
	font-size: ${theme.fontSizes.sm};
	font-weight: ${theme.fontWeight.semiBold};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;