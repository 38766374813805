import React from 'react'
import {
	HomeButton,
	ZoomInButton,
	ZoomOutButton,
} from 'components/form/buttons/IconButtons'
import 'sass/components/seatingChart/ZoomButtons.scss'

export const ZoomButtons = ({
	className,
	scale,
	isSmallDevice,
	mobileScale,
	...props
}) => {
	const isZoomButtonDisabled = (inOrOut) => {
		let mobileMinLeeway = mobileScale && mobileScale.min * 1.05
		let mobileMaxLeeway = mobileScale && mobileScale.max * 0.95

		switch (inOrOut) {
			case 'in':
				if (isSmallDevice) {
					return (
						mobileScale.current >= mobileScale.max ||
						mobileScale.current >= mobileMaxLeeway
					)
				}
				return scale && scale.current === scale.max
			case 'out':
				if (isSmallDevice) {
					return (
						mobileScale.current <= mobileScale.min ||
						mobileScale.current <= mobileMinLeeway
					)
				}
				return scale && scale.current <= scale.min
			default:
				throw new Error(
					`isZoomButtonDisabled must be called with 'in' or 'out'`
				)
		}
	}

	const isHomeZoomDisabled = () => {
		if (isSmallDevice) {
			return mobileScale.current === mobileScale.home
		}

		return (
			scale &&
			scale.current === scale.home &&
			(props && props.hasPan ? !props.hasPan : true)
		)
	}

	return (
		<nav className={`ot_zoomButtons ${className}`}>
			<div className='zoomButtonWrap'>
				<div key='0'>
					<HomeButton
						onClick={() => props.onHome()}
						// data-hint="Zoom Home"
						disabled={isHomeZoomDisabled()}
						id={`zoomHome`}
						onMouseOver={props.onMouseOver}
					/>
				</div>
				<div className='zoomButtonDivider'></div>
				<div key='1'>
					<ZoomInButton
						onClick={props.onZoomIn}
						// data-hint="Zoom In"
						disabled={isZoomButtonDisabled('in')}
						id={`zoomIn`}
						onMouseOver={props.onMouseOver}
					/>
				</div>
				<div className='zoomButtonDivider'></div>
				<div key='2'>
					<ZoomOutButton
						onClick={props.onZoomOut}
						// data-hint="Zoom Out"
						disabled={isZoomButtonDisabled('out')}
						id={`zoomOut`}
						onMouseOver={props.onMouseOver}
					/>
				</div>
			</div>
		</nav>
	)
}
