import 'whatwg-fetch'
import BaseAction from 'actions/BaseAction'
import { GlobalEvents } from 'actions/Events'

let ReduxDispatcher = null
export default class BaseActionCreator {
	constructor(type, configuration) {
		this.setConfig(type, configuration)
	}

	setConfig() {
		this.host = process.env.REACT_APP_CONFIG_CI_URL
		this.headers = {}
		this.contextRoot = process.env.REACT_APP_CONFIG_CONTEXT_ROOT
		this.dataType = 'json'
	}
	constructUrl(url) {
		var path = this.contextRoot + url
		return this.host + path.replace(new RegExp('//', 'g'), '/')
	}
	dispatch() {
		BaseActionCreator.dispatchAction.apply(this, arguments)
	}
	static dispatchAction() {
		if (ReduxDispatcher) {
			ReduxDispatcher.dispatch.apply(null, arguments)
		}
	}
	logFrontEndError(data) {
		this.post('/logFrontEndError', data)
	}
	static setDispatcher(dispatcher) {
		ReduxDispatcher = dispatcher
	}
	static showNotification(title, message) {
		BaseActionCreator.dispatchAction({
			type: BaseAction.NOTIFICATION_EVENT_TYPE,
			data: {
				title: title,
				body: message,
			},
		})
	}
	static changeRouterPath(path, query) {
		BaseActionCreator.dispatchAction({
			type: BaseAction.ROUTE_CHANGE_ACTION,
			data: {
				pathname: path,
				query: query,
			},
		})
	}
	showWarnings(warnings) {
		this.dispatch({
			type: GlobalEvents.WARNING_MESSAGES_LOAD_EVENT,
			data: { warnings },
		})
	}
	callToAction(data) {
		this.dispatch({ type: GlobalEvents.CALL_TO_ACTION_NOTIFICATION, data })
	}
	changeRoute(path, query) {
		BaseActionCreator.changeRouterPath(path, query)
	}
	raiseFailure(event, options = {}, source) {
		return function (failureDetails) {
			Object.assign(failureDetails, options.additionalData || {})
			if (failureDetails.alreadyRejected) {
				return Promise.reject({
					alreadyRejected: true,
					details: failureDetails.details,
				})
			}
			if (options && options.logError) {
				this.logFrontEndError(failureDetails)
			}
			this.dispatch({ type: event, data: failureDetails, source })
			if (!options || !options.silent) {
				this.dispatch({
					type: BaseAction.ERROR_EVENT_TYPE,
					data: failureDetails,
					source,
				})
			}
			return options && options.chain
				? Promise.reject({ alreadyRejected: true, details: failureDetails })
				: Promise.reject(failureDetails)
		}.bind(this)
	}
	raiseInlineFailure(event, options = {}) {
		return function (failureDetails) {
			Object.assign(failureDetails, options.additionalData || {})
			if (failureDetails.alreadyRejected) {
				return Promise.reject({
					alreadyRejected: true,
					details: failureDetails.details,
				})
			}
			this.dispatch({ type: event, data: failureDetails })
			return Promise.reject(failureDetails)
		}.bind(this)
	}
	post(url, data, headers = {}, options = { constructUrl: true }) {
		if (typeof data === 'undefined') {
			throw new Error('Data is required for post.')
		}
		return this.makeWebCall(url, 'POST', data, headers, options)
	}
	get(url, data, headers = {}, options = { constructUrl: true }) {
		return this.makeWebCall(url, 'GET', data, headers, options)
	}
	put(url, data = {}, headers = {}, options = { constructUrl: true }) {
		return this.makeWebCall(url, 'PUT', data, headers, options)
	}
	patch(url, data = {}, headers = {}, options = { constructUrl: true }) {
		return this.makeWebCall(url, 'PATCH', data, headers, options)
	}
	delete(url, data = {}, headers = {}, options = { constructUrl: true }) {
		return this.makeWebCall(url, 'DELETE', data, headers, options)
	}
	makeWebCall(
		url,
		verb = 'GET',
		data,
		headers = {},
		options = { constructUrl: true, isJsonData: true }
	) {
		if (!url) {
			throw new Error('Url is required for web call.')
		}
		var _this = this
		if (options && options.constructUrl) {
			url = _this.constructUrl(url)
		}
		headers = Object.assign(
			{
				pragma: 'no-cache',
				'cache-control': 'no-store',
			},
			this.headers,
			headers
		)
		return new Promise((resolve, reject) => {
			let params = {
				headers: headers,
				method: verb,
				credentials: 'include',
			}
			if (data !== null && typeof data !== 'undefined') {
				params.body = JSON.stringify(data)
			}
			fetch(url, params).then((response) => {
				var processResponse = (rawText) => {
					let responseType = response.headers.get('Content-Type')
					let jsonResponse = null
					if (responseType == null) {
						resolve(null, response)
						return
					}
					if (
						responseType.indexOf('application/json') >= 0 ||
						responseType.indexOf('application/javascript') >= 0
					) {
						jsonResponse = JSON.parse(rawText)
					}
					if (response.status >= 200 && response.status < 300) {
						if (
							options &&
							options.displayWarnings &&
							jsonResponse &&
							((jsonResponse.infoMessages &&
								jsonResponse.infoMessages.length > 0) ||
								(jsonResponse.warnings && jsonResponse.warnings.length > 0))
						) {
							this.dispatch({
								type: GlobalEvents.WARNING_MESSAGES_LOAD_EVENT,
								data: jsonResponse,
							})
						}
						resolve(jsonResponse, response)
					} else {
						reject({
							xhr: response,
							data: jsonResponse,
							rawData: rawText,
							status: response.status,
							detail: response.statusText,
							url: url,
						})
					}
				}
				response.text().then(processResponse)
			})
		})
	}

	static makeWebCall(
		url,
		verb = 'GET',
		data,
		headers = {},
		options = { constructUrl: true }
	) {
		var helper = new BaseActionCreator()
		return helper.makeWebCall(url, verb, data, headers, options)
	}
	static makeWebCallSync(
		url,
		verb = 'GET',
		data,
		headers = {},
		options = { constructUrl: true }
	) {
		var xhr = new XMLHttpRequest()
		if (options && options.constructUrl) {
			url = new BaseActionCreator().constructUrl(url)
		}
		var ret = null
		headers = Object.assign({}, new BaseActionCreator().headers, headers)
		xhr.open(verb, url, false)
		// xhr.withCredentials = true;
		Object.keys(headers).forEach((key) => {
			xhr.setRequestHeader(key, headers[key])
		})
		xhr.onerror = function () {
			throw new Error(`XHR error: ${xhr.statusText}`)
		}
		xhr.send(JSON.stringify(data))
		if (xhr.status === 200) {
			//ret=JSON.parse(xhr.responseText);
			try {
				const contentType = xhr.getResponseHeader('content-type')
				if (contentType && contentType.includes('application/json')) {
					const ret = JSON.parse(xhr.responseText)
					console.log('Parsed JSON:', ret)
				} else {
					throw new Error(`Expected JSON response but received: ${contentType}`)
				}
			} catch (error) {
				console.error('Error parsing JSON:', error)
			}
		} else {
			throw new Error(`Unexpected response received: ${xhr.status}`)
		}
		return ret
	}
}
