import axios from 'axios';
import Cookies from 'js-cookie';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const addToCart = async (seatObj) => {
    const token = Cookies.get('token');
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    };
    try {
        const response = await axios.post(`${API_BASE_URL}/websocket/addSeat`, seatObj, { headers });
        return response.status === 200;
    } catch (error) {
        console.error('Error websocket/addSeat :', error);
        return false;
    }
};
