import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { colors, theme } from '../../../theme'

const TooltipContainer = styled.div`
	position: absolute;
	background-color: #fff;
	color: white;
	white-space: nowrap;
	margin-left: ${theme.margin.lg};
	border-radius: ${theme.borderRadius.xllg};
	box-shadow: ${theme.boxShadows.shadow9};
	border: 1px solid ${colors.outlines.outline};
`

const TooltipText = styled.div`
	font-size: 14px;
	padding: ${theme.padding.md};
	text-align: center;
	color: black;
	line-height: 20px;
`

const tooltipElement = (x, y, children, isTooltipVisible) => (
	<AnimatePresence>
		{isTooltipVisible && (
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				style={{ left: x, top: y, position: 'absolute' }}
				transition={{ duration: 0.15 }}
			>
				<TooltipContainer>
					<TooltipText>{children}</TooltipText>
				</TooltipContainer>
			</motion.div>
		)}
	</AnimatePresence>
)

const Tooltip = React.memo(
	({
		x,
		y,
		children,
		isTooltipVisible,
		portalElement = null,
	}) => {
		if (!portalElement) return tooltipElement(x, y, children, isTooltipVisible)

		return ReactDOM.createPortal(
			tooltipElement(x, y, children, isTooltipVisible),
			document.getElementById(portalElement)
		)
	}
)

Tooltip.displayName = 'Tooltip'

const areEqual = (prevProps, nextProps) => {
	return (
		prevProps.seat === nextProps.seat &&
		prevProps.x === nextProps.x &&
		prevProps.y === nextProps.y &&
		prevProps.children === nextProps.children &&
		prevProps.isTooltipVisible === nextProps.isTooltipVisible
	)
}

export default React.memo(Tooltip, areEqual)
