import React from 'react'

import { MapLegend } from 'components/seatingChart/seatSelection/MapLegend'
import { AccessibilitySeatFilter } from 'components/seatingChart/seatSelection/AccessibilitySeatFilter'
import 'sass/components/seatingChart/seatSelection/mapToolbars.scss'

export class MapToolbar extends React.PureComponent {
	state = {
		updateFilters: [],
		accessibilityPriceFilter: [],
		priceRangeFilter: [],
		accessibilityChecked: false,
		priceRangeFilterChecked: false,
		selectedFilter: null,
	}

	updatePriceFilterRange = (priceLevels, priceRangeFilterChecked) => {
		this.setState(
			{ priceRangeFilter: [...priceLevels], priceRangeFilterChecked },
			() => {
				this.onFilterChange()
			}
		)
	}

	updateAccessibilitySeatFilter = (priceLevels, accessibilityChecked) => {
		this.setState(
			{
				accessibilityPriceFilter: [...priceLevels],
				accessibilityChecked: accessibilityChecked,
			},
			() => {
				this.onFilterChange()
			}
		)
	}

	onFilterChange = () => {
		let intersection = []
		let accessibilityChecked = this.state.accessibilityChecked
		let priceRangeFilterChecked = this.state.priceRangeFilterChecked
		if (accessibilityChecked && !priceRangeFilterChecked) {
			intersection = this.state.accessibilityPriceFilter
		} else if (accessibilityChecked && priceRangeFilterChecked) {
			intersection = this.state.accessibilityPriceFilter.filter((element) =>
				this.state.priceRangeFilter.includes(element)
			)
		} else if (!accessibilityChecked && priceRangeFilterChecked) {
			intersection = this.state.priceRangeFilter
		} else {
			let allSeats = []
			let priceLevels = this.props.priceLevels
			if (priceLevels && priceLevels.length > 0) {
				for (let priceLevel of priceLevels) {
					if (priceLevel.priceLevel) {
						allSeats.push(priceLevel.priceLevel.id)
					}
				}
			}
			intersection = allSeats
		}
		this.setState({ updateFilters: [...intersection] }, () => {
			this.props.onFilterChange(this.state.updateFilters)
		})
	}

	render() {
		let {
			showOutline,
			noToolBar,
			isSmallDevice,
			isFixedPackageSeating,
			featureFlags,
			...props
		} = this.props
		let { selectedFilter } = this.state

        const isAccessibilityFilterEnabled = featureFlags.find(
            flagObj => flagObj.flag === 'is-sc-accessibility-filter-enabled')?.value || false;
        const isSCLegendEnabled = featureFlags.find(
            flagObj => flagObj.flag === 'is-sc-legend-enabled')?.value || false;

		return (
			<React.Fragment>
				{!noToolBar && (
					<div id='mapToolbar' className='ot_mapToolbar'>
						<div
							className='filterBar'
							onClick={() => this.setState({ selectedFilter: 'price' })}
							style={{ zIndex: selectedFilter === 'price' ? 9999 : 'initial' }}
							role='button'
							aria-pressed={selectedFilter === 'price'}
						>
							{/* PriceRangeFilter */}
						</div>
						<div
							className='filterBar'
							onClick={() => this.setState({ selectedFilter: 'accessibility' })}
							style={{
								zIndex: selectedFilter === 'accessibility' ? 9999 : 'initial',
							}}
							aria-pressed={selectedFilter === 'accessibility'}
						>
							{isAccessibilityFilterEnabled && (
								<AccessibilitySeatFilter
									{...props}
									onFilterChange={this.updateAccessibilitySeatFilter}
								/>
							)}
						</div>
					</div>
				)}
				{isSCLegendEnabled && !((noToolBar && isSmallDevice) || isFixedPackageSeating) && (
					<div className={`legendWrap`}>
						<MapLegend
							priceLevels={props.priceLevels}
							showOutline={showOutline}
							currencySymbol={props.currencySymbol}
						/>
					</div>
				)}
			</React.Fragment>
		)
	}
}
