import React from 'react'

/**
 * A label for displaying error messages
 */
export const ErrorLabel = (props) => {
	var { passedProps } = props
	return (
		<span
			className={`ot_error_label ${props.className || ''}`}
			{...passedProps}
		>
			{props.show && props.text}
		</span>
	)
}
